import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/scss/template/_customSlider.scss";

const testimonials = [
  {
    id: 1,
    text: "Ayudamos a optimizar su proceso de reclutamiento y gestión de talento humano implementando GraiQ, una herramienta que mejora la selección de personal y facilita la toma de decisiones estratégicas.",
    logo: "/images/asia.png",
    company: "Asica Group",
  },
  {
    id: 2,
    text: "Potenciamos su transformación digital mediante soluciones en BI, Analytics y automatización de pruebas, agilizando sus procesos tecnológicos y mejorando la experiencia del usuario con aplicaciones robustas y eficientes.",
    logo: "/images/yanbal.png",
    company: "Yanbal",
  },
  {
    id: 3,
    text: "Simplificamos su gestión administrativa desarrollando sistemas de facturación electrónica, optimización de pagos y soluciones de integración bancaria que garantizan operaciones confiables y seguras.",
    logo: "/images/cabify.png",
    company: "Cabify",
  },
  {
    id: 4,
    text: "Mejoramos el control y seguimiento de sus operaciones con sistemas avanzados de gestión para sus unidades, automatización de pagos a conductores y monitoreo en tiempo real, asegurando un servicio eficiente.",
    logo: "/images/taxitel.png",
    company: "TaxiTell",
  },
];

const CustomSlider = () => {
  const slideSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="custom-slider-wrapper">
      <Slider {...slideSettings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="custom-slider-card">
            <p className="custom-slider-text">{testimonial.text}</p>
            <div className="custom-slider-content-wrapper">
              <img
                src={testimonial.logo}
                alt={`${testimonial.company} logo`}
                className="custom-slider-logo"
              />
              <h4 className="custom-slider-title">{testimonial.company}</h4>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
