import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BlogListOne from '../../component/blog/BlogListOne';


const BlogOne = () => {
    return (
        <div className="section section-padding-equal">
            <div className="container">
                <SectionTitle 
                    subtitle="Desde nuestro blog"
                    title="Entérate de las ultimas novedades"
                    textAlignment=""
                    textColor=""
                />
                <div className="row g-0"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}>
                    <BlogListOne colSize="col-xl-4" itemShow="3" />
                </div>
            </div>
            
        </div>
    )
}

export default BlogOne;