import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import BlogOne from "../component/blog/BlogOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import BannerFive from "../component/banner/BannerFive";
import HeaderFour from "../common/header/HeaderFour";
import AboutFour from "../component/about/AboutFour";
import CustomSlider from "../component/banner/CustomSlider";

const CorporateAgency = () => {
  return (
    <>
      <SEO title="Corporate Agency" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderFour />
        <BannerFive />

        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Queremos HACERLO POSIBLE"
              title="Transformamos tu empresa<br>con soluciones tecnológicas<br> a medida."
              description=""
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />

            <div className="row">
              <ServicePropOne
                colSize="col-lg-4 col-md-6"
                serviceStyle="service-style-2"
                itemShow="3"
                marginTop="no"
              />
            </div>
          </div>
        </div>
        <AboutFour />
        <ProjectOne parentClass="bg-color-light" />
        <div
          className="section"
          style={{
            paddingTop: "100px",
            paddingBottom: "100px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <div className="container">
            <SectionTitle
              subtitle="Queremos HACERLO POSIBLE"
              title="Nos enfocamos en dar valor<br>a nuestros Clientes"
              textAlignment=""
              textColor=""
              style={{ textAlign: "center", margin: "0 auto" }}
            />
            <CustomSlider />
          </div>
        </div>

        <BlogOne />
        <CtaLayoutOne />
        <FooterOne />
      </main>
    </>
  );
};

export default CorporateAgency;
