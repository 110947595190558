import React, { useState, useEffect } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectData from "../../data/project/ProjectData.json";

const filters = [
  {
    id: 1,
    label: "Adopción de la IA",
  },
  {
    id: 2,
    label: "Equipos Potentes TI",
  },
  {
    id: 3,
    label: "Fábricas de Software",
  },
];

const AllData = ProjectData;

const ProjectOne = ({ parentClass, colSize, itemShow, columnGap }) => {
  const [getAllItems] = useState(AllData);
  const [visiableProject] = useState(itemShow ? itemShow : 4);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setActiveFilter(filters[0].label);
    setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    let target = e.target.textContent;

    setActiveFilter(target);

    let tempData = [];
    if (target === filters[0].label) {
      tempData = getAllItems.filter((data) => data.id <= visiableProject);
    } else {
      for (let i = 0; i < getAllItems.length; i++) {
        const element = getAllItems[i];
        let categories = element["category"];

        if (categories.includes(target)) {
          tempData.push(element);
        }
      }
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <div
        className={`section section-padding-2 ${
          parentClass ? parentClass : ""
        }`}
      >
        <div className="container">
          <SectionTitle
            subtitle="Conoce Nuestros Proyectos"
            title="Soluciones tecnológicas que <br> impulsan tu empresa"
            textAlignment=""
            textColor=""
            style={{ textAlign: "center", margin: "0 auto" }}
          />

          <div
            className="isotope-button isotope-project-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              margin: "0 auto",
			  paddingBottom:"80px",
            }}
          >
            {filters.map((filter) => (
              <button
                onClick={handleChange}
                className={filter.label === activeFilter ? "is-checked" : " "}
                key={filter.id}
              >
                {filter.label}
              </button>
            ))}
          </div>
          <div className={`row ${columnGap ? columnGap : "row-35"}`}>
            {visibleItems.map((data) => (
              <div className={colSize ? colSize : "col-md-6"} key={data.id}>
                <ProjectPropOne projectStyle="" portfolio={data} />
              </div>
            ))}
          </div>
        </div>

      </div>
    </>
  );
};

export default ProjectOne;
