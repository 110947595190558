import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import BlogData from "../../data/blog/BlogData.json";

const allBlogData = BlogData;

const BlogListOne = ({ colSize, itemShow }) => {
    return (
        <>
            {allBlogData.slice(0, itemShow).map((data) => (
                <div className={`${colSize}`} key={data.id}>
                    <div className="blog-list-one">
                        {/* Imagen primero */}
                        <div className="post-thumbnail">
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/${data.thumb}`}
                                    alt="Blog Post"
                                    className="blog-image"
                                />
                            </Link>
                        </div>
                        {/* Contenido abajo */}
                        <div className="post-content">
                            <h5 className="title">
                                <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                                    {data.title}
                                </Link>
                            </h5>
                            <p>{data.excerpt}</p>
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="more-btn">
                                Learn more <FaAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default BlogListOne;
